// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
 MERCHANT_URL: 'https://prod.merchant.localfirst.co.in/v1',
  MERCHANT_SUB_URL: 'https://prod.subscription.localfirst.co.in/v1',
  REFFERALS_URL: 'https://prod.referrals.localfirst.co.in/v1',
  RAZOR_PAY_ID: 'rzp_live_SSUlHwyUhZ3uJy',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
